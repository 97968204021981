import { useMedia } from 'react-use'

const MEDIA = {
  MOBILE: '(max-width: 47.999rem)',
  TABLET: '(min-width: 48rem)',
  TABLET_OR_MOBILE: '(max-width: 62.999rem)',
  TABLET_OR_DESKTOP: '(max-width: 62.999rem)',
  TABLET_ONLY: '(min-width: 48rem) and (max-width: 62.999rem)',
  DESKTOP: '(min-width: 63rem)',
}

export const useIsMobile = (defaultState: boolean = false) => {
  return useMedia(MEDIA.MOBILE, defaultState)
}

export const useIsTablet = (defaultState: boolean = false) => {
  return useMedia(MEDIA.TABLET, defaultState)
}

export const useIsTabletOrMobile = (defaultState: boolean = false) => {
  return useMedia(MEDIA.TABLET_OR_MOBILE, defaultState)
}

export const useIsTabletOnly = (defaultState: boolean = false) => {
  return useMedia(MEDIA.TABLET_ONLY, defaultState)
}

export const useIsDesktop = (defaultState: boolean = false) => {
  return useMedia(MEDIA.DESKTOP, defaultState)
}

type OptionSize = `${number}${'px' | 'vw' | 'rem'}`
export type GetImageSizeOptions = {
  mobile?: OptionSize
  tablet?: OptionSize
  default: OptionSize
}

export const getAdaptiveImageSize = (options: GetImageSizeOptions): string => {
  //NOTE: Order is important. Should be from small to big
  const configs = [
    options.mobile && {
      media: MEDIA.MOBILE,
      size: options.mobile,
    },
    options.tablet && {
      media: MEDIA.TABLET_OR_MOBILE,
      size: options.tablet,
    },
    options.default && {
      media: '',
      size: options.default,
    },
  ].filter(Boolean)

  return configs.reduce((acc, mediaConfig) => {
    if (!mediaConfig) return acc

    if (acc.length > 0) {
      acc += ', '
    }

    return acc + `${mediaConfig.media} ${mediaConfig.size}`.trim()
  }, '')
}
